import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SignUpBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import "../../../assets/scss/TenantMangement.scss";
import "../../../assets/scss/BackOfficeStyles/Common.scss";
import "../../../assets/scss/Usermanagement.scss";
import del from "../../../assets/vizr_images/ic_rubbish_bin.png";
import EnhancedTable from "../../shared/alertsTable";

import { makeStyles } from "@material-ui/core/styles";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import {
  ALL_USERS,
  DEL_USERS,
  ALL_ROLES,
  ROLES,
} from "../../../redux/constants/constants";
// import { ALL_USERS } from "../../../redux/constants/constants";
import {
  headCellsForUsersList,
  headCellsForRoles,
} from "../../common/headCells";
import Popper from "@material-ui/core/Popper";
import { AddButtonForAlertTable } from "../../common/buttons";
import SnackBar2 from "../../../components/shared/SnackBar2";
import { useLocation } from "react-router-dom";

export const RolesContext = React.createContext();
const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  roles_popper: {
    height: "200px",
    width: "300px",
    backgroundColor: "#ffffff",
    boxShadow: "0 6px 8px 0 rgba(0,0,0,0.2)",
    display: "flex !important",
    flexDirection: "column !important",
  },
  roles_popperInput: {
    textAlign: "center",
  },
}));

const UserManagement = () => {
  const dispatch = useDispatch();
  const SelectedArr = useSelector(
    (state) => state.selectedItemsData.selectedItems
  );
  const userDeleted = useSelector(
    (state) => state.selectedItemsData.isUsersDeleted
  );
  const rolesDeleted = useSelector(
    (state) => state.selectedItemsData.isRolesDeleted
  );

  const [page, setPageNumber] = useState(1);
  const classes = useStyles();
  let location = useLocation();
  const project = location.pathname === "/mdr/userManagement" || "/gov/userManagement" ? "MDR" : "";

  const [usersActive, setUsersActive] = useState(true);
  const [rolesActive, setrolesActive] = useState(false);
  const [gridData, setgridData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [rolecreatedSuccess, setRolesuccessMessage] = useState("");
  const [rolefailure, setrolefailure] = useState("");
  const [rolesInfo, setRolesInfo] = useState({ roleName: "" });
  const [usersDeletedSuccess, setusersDeletedSuccess] = useState(false);
  const [roleDeletedSuccess, setroleDeletedSuccess] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [getAllRolesCall, setAllRolesCall] = useState(true);
  const handleGetAllRolesCall = (bool) => {
    if (!bool) {
      setAllRolesCall(false);
    } else {
      setAllRolesCall(true);
    }
  };
  const [getAllUsersCall, setAllUsersCall] = useState(true);
  const handleGetAllUsersCall = (bool) => {
    if (!bool) {
      setAllUsersCall(false);
    } else {
      setAllUsersCall(true);
    }
  };

  const activeToggle = (payload) => {
    if (payload === "users") {
      setUsersActive(true);
      setrolesActive(false);
    } else if (payload === "roles") {
      setUsersActive(false);
      setrolesActive(true);
    }
  };
  const getUsers = async (afterDel) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: ALL_USERS(headers.Tenant ? headers.Tenant : "", 20, 0),
      headers: headers,
    });
    const gridData = (await res) ? (res.data ? res.data.content : []) : [];
    setTotalElements(res.data ? res.data.totalElements : "");
    setgridData([...gridData]);
    setDataLoaded(true);
  };

  const getRoles = async (size) => {
    const headers = getAxiosHeaders(true);
    const res = await getService({
      method: "GET",
      url: ALL_ROLES(headers.Tenant ? headers.Tenant : "", size ? size : 20, 0),
      headers: headers,
    });
    const gridData = (await res) ? (res.data ? res.data.content : []) : [];

    setRolesData([...gridData]);
    setTotalElements(res.data ? res.data.totalElements : "");
    setRolesuccessMessage(false);
  };

  useEffect(() => {
    getUsers();
    getRoles();
  }, []);
  useEffect(() => {
    if (userDeleted && SelectedArr.length === 1) {
      const delUsers = async () => {
        const headers = getAxiosHeaders(true);
        const res = await getService({
          method: "DELETE",
          url: DEL_USERS(headers.Tenant ? headers.Tenant : "", SelectedArr[0]),
          headers: headers,
        });
        if (res.status === 204) {
          setusersDeletedSuccess(true);
          getUsers();
          dispatch({ type: "SELECTED_ITEMS", selectedArray: [] });
        }
      };
      delUsers();
    } else if (rolesDeleted && SelectedArr.length === 1) {
      const delRoles = async () => {
        const headers = getAxiosHeaders(true);
        const res = await getService({
          method: "DELETE",
          url: ROLES(headers.Tenant ? headers.Tenant : "", SelectedArr[0]),
          headers: headers,
        });
        if (res.status === 200) {
          setroleDeletedSuccess(true);
          getRoles();
          dispatch({ type: "SELECTED_ITEMS", selectedArray: [] });
        }
      };
      delRoles();
    }
  }, [userDeleted, SelectedArr, dispatch, rolesDeleted]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const rolesSubmitHandler = () => {
    const createRoles = async () => {
      const headers = getAxiosHeaders(true);
      const res = await getService({
        method: "POST",
        url: ALL_ROLES(headers.Tenant ? headers.Tenant : ""),
        headers: headers,
        data: {
          name: rolesInfo.roleName,
          description: "",
          permissions: [""],
        },
      });
      if (res.status === 201) {
        setRolesuccessMessage(true);
        getRoles();
      } else {
        setrolefailure(true);
      }
    };
    createRoles();
  };

  const handler = () => {
    setusersDeletedSuccess(false);
    setroleDeletedSuccess(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  return (
    <div className="UserManagement">
      <div
        style={{
          overflowX: "hidden",
          overflowY: "hidden" /* Hide horizontal scrollbar */,
          minHeight: "150vh",
        }}
      >
        {rolecreatedSuccess && (
          <SnackBar2
            success
            successMessage="Role created Successfully"
          ></SnackBar2>
        )}
        {rolefailure && (
          <SnackBar2
            failure
            errorMessage="Role created failed Please try again"
          ></SnackBar2>
        )}
        <SnackBar2
          failure={
            SelectedArr.length > 1 && userDeleted
              ? userDeleted
              : SelectedArr.length > 1 && rolesDeleted
              ? rolesDeleted
              : ""
          }
          errorMessage={
            SelectedArr.length > 1 && userDeleted
              ? "User Delete failed as we have multiple users Selected. Please select one user to delete"
              : SelectedArr.length > 1 && rolesDeleted
              ? "Roles Delete failed as we have multiple roles Selected. Please select one roles to delete"
              : ""
          }
          success={
            usersDeletedSuccess
              ? usersDeletedSuccess
              : roleDeletedSuccess
              ? roleDeletedSuccess
              : ""
          }
          successMessage={
            roleDeletedSuccess
              ? "Role deleted successfully"
              : "Users deleted successfully"
          }
          handler={handler}
          setroleDeletedSuccess={setroleDeletedSuccess}
          setusersDeletedSuccess={setusersDeletedSuccess}
        ></SnackBar2>
        <div className="description">
          <div style={{ display: "grid" }}>
            <h2 style={{ margin: "0", fontSize: "32px" }}>User Management</h2>
            <p style={{ margin: "0" }}>
              Manage Data Vision Users and roles
            </p>
          </div>
        </div>
          <div className="header_toggler">
            <div
              className={usersActive ? "header_toggler_active" : "header_toggler_inactive"}
              onClick={() => activeToggle("users")}
            >
              Users
            </div>
        </div>
        <div>
          {usersActive && (
            <div className="filter-table-container">
              <div className="table-container">
                <EnhancedTable
                  tableData={gridData}
                  headCells={headCellsForUsersList()}
                  check={true}
                  loc="users"
                  getAllUsersCall={getAllUsersCall}
                  totalElements={totalElements}
                  handleGetAllUsersCall={handleGetAllUsersCall}
                  dataLoaded={dataLoaded}
                  project={project}
                  description={
                    "Data Vision users, roles and their status. Click on the Edit icon view details and/or edit."
                  }
                />
              </div>
            </div>
          )}
          {rolesActive && (
            <div className="filter-table-container">
              <div className="table-container">
                <RolesContext.Provider value="Roles">
                  <EnhancedTable
                    tableData={rolesData}
                    setRolesData={setRolesData}
                    headCells={headCellsForRoles}
                    check={true}
                    loc="roles"
                    rolesClickHandler={handleClick}
                    totalElements={totalElements}
                    handleGetAllRolesCall={handleGetAllRolesCall}
                    getAllRolesCall={getAllRolesCall}
                  />

                  <Popper id={id} open={open} anchorEl={anchorEl}>
                    <div className={classes.roles_popper}>
                      <input
                        type="text"
                        className="roles_input"
                        placeholder="Role Name"
                        value={rolesInfo.roleName}
                        onChange={(event) =>
                          setRolesInfo({
                            ...rolesInfo,
                            roleName: event.target.value,
                          })
                        }
                      ></input>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <AddButtonForAlertTable
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ marginRight: "20px" }}
                          onClick={rolesSubmitHandler}
                        >
                          Save
                        </AddButtonForAlertTable>
                        <AddButtonForAlertTable
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(event) =>
                            setAnchorEl(anchorEl ? null : event.currentTarget)
                          }
                        >
                          cancel
                        </AddButtonForAlertTable>
                      </div>
                    </div>
                  </Popper>
                </RolesContext.Provider>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
